var privacy_data_pomo_zh = `
在使用我们提供的服务之前，请务必仔细阅读本声明，本声明的发布者为Cigam Tech

请您仔细阅读以下内容，如果您不同意以下任何内容，请立刻停止使用我们的服务。

我们非常重视保护您的隐私。

为方便您登录、使用相关服务，以及为您提供更个性化的用户体验和服务，您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本隐私声明向您说明，在使用我们提供的服务时，我们如何收集、使用、储存和披露您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。希望您仔细阅读。

您使用我们的服务，即意味着您已经同意我们按照本隐私声明收集、使用、储存和披露您的相关信息，以及向您提供的控制和保护措施。

<strong>1. 信息收集范围</strong>

1.1 为完成账户的注册、登录、内购等必要活动，您需要提交真实、合法、有效的信息，包括但不限于联系电话等。

1.2 为提供持续服务和保证服务质量所需，我们在您使用本服务期间，将记录并保存您登录和使用本服务的信息，包括但不限于软硬件特征信息、用户标识符等。

1.3 关于健康（即 Health Kit，后续统一表述为健康）数据说明：

&nbsp&nbsp a. 经过你的授权，我们将向健康中记录你在使用我们的服务时所产生的数据（例如正念时间、睡眠状况）。

&nbsp&nbsp b. 经过你的授权，我们将读取健康中其他应用所记录的数据（睡眠状况）用于进行数据展示。

&nbsp&nbsp c. 我们承诺你通过我们使用健康的数据不会用于广告或应用其他商业化服务目的。

&nbsp&nbsp d. 我们承诺不会向任何第三方展示、出售任何健康数据，并且我们承诺所获取和记录的健康数据仅存储于你的设备本地和你的 iCloud 备份中。

<strong>2. 如何收集信息</strong>

2.1 我们通过您主动提交，以及在您使用我们的服务过程中产生相应的记录等方式收集您的信息。

2.2 我们可能会通过偏好设置和其他相关技术收集和使用您的信息。我们使用偏好设置的具体用途包括：

&nbsp&nbsp a. 记住您的身份。例如：偏好设置有助于我们辨认您作为我们的注册用户的身份。

&nbsp&nbsp b. 分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括键盘设置等服务。

<strong>3. 信息使用</strong>

为了向您提供更加优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息用作以下用途：

&nbsp&nbsp a. 向您提供服务。

&nbsp&nbsp b. 满足您的个性化需求。例如，语言设定、位置设定、个性化的帮助服务和指示等。

&nbsp&nbsp c. 服务优化和开发。例如，我们会根据服务响应您的需求时产生的信息，优化我们的服务。

&nbsp&nbsp d. 其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。

<strong>4. 信息披露</strong>

未经您同意，我们不会与任何无关第三方分享您的信息，以下情形除外：

4.1 我们可能将您的信息与我们的关联公司、第三方服务提供商、承包商及代理分享，仅用作下列用途：

&nbsp&nbsp a. 提供“二、信息收集范围”中的相应功能或服务所必需，以及出于“四、信息使用”中的部分所述目的所必需。

&nbsp&nbsp b. 履行我们在《用户使用协议》或本声明中的义务和行使我们的权利。

如我们与任何上述第三方分享您的信息，我们将努力确保第三方在使用您的信息时遵守本声明及我们要求其遵守的其他适当的保密和安全措施。

4.2 随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的要求，在转移前通知您，确保信息在转移时的机密性，以及变更后继续履行相应责任和义务。

4.3 我们还可能因以下原因而披露您的信息：

&nbsp&nbsp a. 遵守适用的法律法规等有关规定。

&nbsp&nbsp b. 遵守法院判决、裁定或其他法律程序的规定。

&nbsp&nbsp c. 遵守相关政府机关或其他有权机关的要求。

&nbsp&nbsp d. 我们有理由确信需遵守法律法规等有关规定。

&nbsp&nbsp e. 为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。

&nbsp&nbsp f. 经过您合法授权的情形。
如我们因上述原因而披露您的信息，我们将在遵守法律法规相关规定及本声明的基础上及时告知您。

<strong>5. 信息存储</strong>

5.1 收集的有关您的信息保存在我们位于中国大陆的服务器。

5.2 通常，我们仅在为您提供服务期间保留您的信息，保留时间不会超过满足相关使用目的所必须的时间。
但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：

&nbsp&nbsp a. 遵守适用的法律法规等有关规定。

&nbsp&nbsp b. 遵守法院判决、裁定或其他法律程序的要求。

&nbsp&nbsp c. 遵守相关政府机关或其他有权机关的要求。

&nbsp&nbsp d. 我们有理由确信需遵守法律法规等有关规定。

&nbsp&nbsp e. 为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。

<strong>6. 信息安全</strong>

6.1 我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。

&nbsp&nbsp a. 我们使用各种安全技术以保障信息的安全。例如，我们将通过服务器多备份、密码加密等安全措施，防止信息泄露、毁损、丢失。

&nbsp&nbsp b. 我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的人员范围，并进行审计，要求他们遵守保密义务。

&nbsp&nbsp c. 我们重视信息安全合规工作，并通过众多国际和国内的安全认证充分保障您的信息安全。

&nbsp&nbsp d. 但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网环境下，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。

若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等有关规定及时上报，并以发送邮件、推送通知、公告等形式告知您相关情况，并向您给出安全建议。

6.2 为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识。我们仅在服务直接导致您个人信息泄露的范围内承担责任，因此，请您妥善保管您的账号及密码信息，避免您的个人信息泄露。除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等个人信息。

<strong>7. 访问与控制</strong>

7.1 您可以在使用我们服务的过程中，访问、修改和删除您的相关信息。您访问、修改和删除信息的方式将取决于您使用的具体服务。

7.2 如您发现我们违反法律法规的规定或者双方的约定收集、使用您的信息，您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的，且无法自行更正的，您也可以要求我们更正。

7.3 在访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账户安全。请您理解，由于技术所限、基于法律法规要求，您的某些请求可能无法进行响应。

7.4 基于法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除。

7.5 您可以自行选择撤回对某些非基本功能或服务对您信息处理的同意，并通过联系客服的方式选择注销账号。

7.6 如您对上述权利实现存在疑问，可以根据“与我们联系”中的相关联系方式与我们取得联系。

<strong>8. 变更</strong>

我们可能适时修订本隐私声明的条款，该等修订构成本《隐私声明》的一部分。如可能造成您在本隐私声明下权利的实质减少或扩大收集、使用信息的范围等重要规则变更时，我们将在修订生效前在显著位置通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本隐私声明的约束。

<strong>9. 与我们联系</strong>

如您对本政策或其他相关事宜有疑问，请根据我们提供的指引，填写相关资料，将您的问题发送至<a href="mailto:cigamtech@outlook.com">cigamtech@outlook.com</a>，我们将尽快审核所涉问题，并在尽快回复。
`

var privacy_data_pomo_zh = privacy_data_pomo_zh.replace(/\r\n/g,"<br>")
var privacy_data_pomo_zh = privacy_data_pomo_zh.replace(/\n/g,"<br>");

export default privacy_data_pomo_zh